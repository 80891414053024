import React, { useState, useEffect } from "react";
import { isMobile } from 'react-device-detect';
import CarouselDesktop2 from "../components/carousel_desktop2";
import CarouselWide2 from "../components/carousel_wide2";
import CarouselMobile2 from "../components/carousel_mobile2";
import CarouselTablet3 from "../components/carousel_tablet3";
import styled from "styled-components";
import Seo from "../components/seo";
import Helmet from "react-helmet";
import useWindowSize from "../hooks/useGatsbyWindowSize";

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  @media (max-width: 550px) {
    display: none;
  }
`;

const Spacer = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  height: 60px;
  cursor: default;
`;

const Products = () => {
  const window_size = useWindowSize();
  const [isIpadLandscape, setIsIpadLandscape] = useState(false);
  const { width } = window_size;

  useEffect(() => {
    const checkOrientation = () => {
      const isLandscape = window.matchMedia("(orientation: landscape)").matches;
      const isIpad = /iPad/.test(navigator.userAgent) && !window.MSStream;
      setIsIpadLandscape(isLandscape && isIpad);
    };

    // Initial check
    checkOrientation();

    // Add event listener for orientation change
    window.addEventListener("orientationchange", checkOrientation);

    return () => {
      // Clean up by removing the event listener
      window.removeEventListener("orientationchange", checkOrientation);
    };
  }, []);

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "light noscroll",
        }}
      />
      <Spacer />
      <Seo title="Products" slug="products" />

      {width>= 2000 && (
        <Container>
          <CarouselWide2 />
        </Container>
      )}
      {width > 851 && width < 2000 && isMobile === false && (
        <Container>
          <CarouselDesktop2 />
        </Container>
      )}
      {width > 851 && width < 2000 && isMobile === true && (
        <Container>
          <CarouselTablet3 />
        </Container>
      )}
      {width < 850 && (
        <Container>
          <CarouselTablet3 />
        </Container>
      )}
      {width <= 549 && <CarouselMobile2 />}
    </>
  );
};

export default Products;
