import React, { useRef } from 'react'
import styled from 'styled-components'
import { useOnScreen } from '../../lib/hooks';
import CurvedImageMobile from '../curved_image_mobile';

const Container = styled.div`
  transition: opacity 1s ease-in-out;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 20px;
`

const Blurb = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  @media(min-width: 1441px) {
      font-size: 17px;
      line-height: 26px;
  }
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none !important;
  border: none;
  margin-top: 20px;
  opacity: 0;
  overflow-y: visible;
  display: block;
  min-height: 80px;
  cursor: pointer;
  transition: all 1s ease-in-out;
  margin-bottom: 40px;
  white-space: pre-line;
  &.h {
    opacity: 0;
  }
  &.active {
    opacity: 1;
  }
  a {
    text-decoration: none;
    color: #000000;
  }
`;

const Slide2 = ({ s, index }) => {


  const ref = useRef();
  const onScreen = useOnScreen(ref, "-10px");

  return (
    <Container>
      <CurvedImageMobile
        active={onScreen}
        blurb={s.description}
        link={`/${s.product.slug.current}`}
        image={s.image.asset.url}
        index={index}
      />
      <Blurb className={onScreen ? 'active' : 'h'}><span ref={ref}>{s.description}</span></Blurb>
    </Container>
  )
}

export default Slide2;
