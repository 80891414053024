import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import CurvedImage from "../curved_image";
import { useKeenSlider } from "keen-slider/react";
import useSessionStorage from "../../hooks/useSessionStorage";
import "keen-slider/keen-slider.min.css";

const Outer = styled.div`
  width: calc(100% - 50px);
  margin: 0 auto;
  position: relative;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dots = styled.div`
  display: none;
  @media (max-width: 820px) {
    position: fixed;
    width: calc(100vw - 50px);
    text-align: center;
    bottom: 30px;
    display: flex;
    column-gap: 23px;
    justify-content: center;
  }
`;

const Blurb = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none !important;
  border: none !important;
  margin-top: 20px;
  opacity: 0;
  overflow-y: visible;
  display: block;
  min-height: 80px;
  cursor: pointer;
  pointer-events: none;
  transition: all 1s ease-in-out;
  white-space: pre-line;
  &.h {
    opacity: 0;
  }
  &.v {
    opacity: 1;
  }
  a {
    text-decoration: none;
    color: #000000;
  }
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #d9d9d9;
  cursor: pointer;
  &.active {
    background-color: #848484;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  .keen-slider:not([data-keen-slider-disabled]) {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    align-content: flex-start;
    display: flex;
    overflow: hidden;
    position: relative;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -khtml-user-select: none;
    width: 100%;
  }
  .keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
    min-height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .keen-slider:not([data-keen-slider-disabled])[data-keen-slider-reverse] {
    flex-direction: row-reverse;
  }
  .keen-slider:not([data-keen-slider-disabled])[data-keen-slider-v] {
    flex-wrap: wrap;
  }
`;

const CarouselTablet3 = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityCarousel {
        edges {
          node {
            slides {
              description
              product {
                slug {
                  current
                }
              }
              image {
                asset {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const [seen, setSeen] = useSessionStorage("products", false);

  const slides = [...data.allSanityCarousel.edges[0].node.slides];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [slidehovered, setSlideHovered] = useState(false);
  const [blurbtext, setBlurbText] = useState(false);

  const WheelControls = (slider) => {
    let touchTimeout
    let position
    let wheelActive

    function dispatch(e, name) {
      position.x -= e.deltaX
      position.y -= e.deltaY
      slider.container.dispatchEvent(
        new CustomEvent(name, {
          detail: {
            x: position.x,
            y: position.y,
          },
        })
      )
    }

    function wheelStart(e) {
      position = {
        x: e.pageX,
        y: e.pageY,
      }
      dispatch(e, "ksDragStart")
    }

    function wheel(e) {
      dispatch(e, "ksDrag")
    }

    function wheelEnd(e) {
      dispatch(e, "ksDragEnd")
    }

    function eventWheel(e) {
      e.preventDefault()
      if (!wheelActive) {
        wheelStart(e)
        wheelActive = true
      }
      wheel(e)
      clearTimeout(touchTimeout)
      touchTimeout = setTimeout(() => {
        wheelActive = false
        wheelEnd(e)
      }, 50)
    }

    slider.on("created", () => {
      slider.container.addEventListener("wheel", eventWheel, {
        passive: false,
      })
    })
  }

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    duration: 1000,
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      perView: 3,
      spacing: 20,
    },
  },
  [WheelControls]
  );

  return (
    <Outer>
      <Container ref={sliderRef} className="keen-slider">
        {slides.map((s, index) => {
          return (
            <div>
              <div className="keen-slider__slide" key={index}>
                <Link to={`/products/${s.product.slug.current}`} key={index}>
                  <CurvedImage
                    blurb={s.description}
                    link={`/${s.product.slug.current}`}
                    image={s.image.asset.url}
                    seen={seen}
                    setSlideHovered={setSlideHovered}
                    setBlurbText={setBlurbText}
                    slidehovered={slidehovered}
                    index={index}
                  />
                </Link>
                <Blurb className="v">{s.description}</Blurb>
              </div>
            </div>
          );
        })}
      </Container>

      {loaded && instanceRef.current && (
        <Dots>
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <Dot
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              ></Dot>
            );
          })}
        </Dots>
      )}
    </Outer>
  );
};

export default CarouselTablet3;
