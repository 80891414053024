import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";

import infiniteArray from 'infinite-array';
import useWindowSize from '../../hooks/useGatsbyWindowSize'
import Slide2 from '../slide2'

const Container = styled.div`
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
  opacity: 1;
  display: none;
  @media (max-width: 549px) {
    pointer-events: auto;
    display: block;
    width: 100%;
    overflow-y: scroll;
    width: calc(100% - 20px);
  }
  &:not(:hover) {
    .blurb {
      opacity: 1;
    }
  }`


const Blurbs = styled.div`
  display: flex;
  column-gap: 20px;
  opacity: 0;
  transition: all 1s ease-in-out;
  transition-delay: 0.1s;
  div {
    flex: 1 0;
    opacity: 1;
    transition: all 1s ease-in-out;
  }
`

const CarouselMobile2 = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityCarousel {
        edges {
          node {
            slides {
              description
              product {
                slug {
                  current
                }
              }
              image {
                asset {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const [slidehovered, setSlideHovered] = useState(false);
  const [blurbtext, setBlurbText] = useState(false);

  const slides = [
    ...data.allSanityCarousel.edges[0].node.slides,
  ];


  return (
    <Container>
      {slides.map((s, index) => {
        return (
          <Slide2
            s={s}
            key={index + 1}
            index={index + 1}
            className={
              slidehovered && slidehovered === index + 1
                ? "container-hover"
                : "container-not-hover"
            }>
          </Slide2>
        );
      })}
    </Container>
  );
};

export default CarouselMobile2;
