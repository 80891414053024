import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import CurvedImage from "../curved_image";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import infiniteArray from "infinite-array";
import useWindowSize from "../../hooks/useGatsbyWindowSize";
import useSessionStorage from "../../hooks/useSessionStorage";

import ArrowLeft from "../../images/arrow_left.svg";
import ArrowRight from "../../images/arrow_right.svg";

const Outer = styled.div`
  width: 100%;
`;

const LeftRightContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 60px;
  z-index: 1;
  display: block;
  @media (max-width: 850px) {
    display: none;
  }
`;

const Left = styled.div`
  display: flex;
  height: 100vh;
  position: absolute;
  width: 50vw;
  cursor: ${(props) =>
    props.seen ? `url(${ArrowLeft}) 0 16, pointer` : `auto`};
`;

const Right = styled.div`
  display: flex;
  height: 100vh;
  width: 50vw;
  position: relative;
  position: absolute;
  right: 0;
  cursor: ${(props) =>
    props.seen ? `url(${ArrowRight}) 51 16, pointer` : `auto`};
`;

const Container = styled.div`
  width: calc(100% - 80px);
  margin-left: 40px;
  margin-right: 40px;
  opacity: 1;
  display: block;
  @media (max-width: 550px) {
    display: none;
  }

  &:not(:hover) {
    .blurb {
      opacity: 0;
    }
  }

  &:hover {
    .blurb-container {
      opacity: 1;
    }
    .container-not-hover {
      opacity: 0;
      .blurb {
        opacity: 0;
      }
    }
    .container-hover p {
      opacity: 1;
    }
  }
`;

const Blurb = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none !important;
  border: none;
  margin-top: 20px;
  opacity: 0;
  overflow-y: visible;
  display: block;
  min-height: 80px;
  cursor: pointer;
  pointer-events: none;
  transition: all 1s ease-in-out;
  white-space: pre-line;
  &.h {
    opacity: 0;
  }
  &.v {
    opacity: 1;
  }
  a {
    text-decoration: none;
    color: #000000;
  }
`;

const Blurbs = styled.div`
  visibility: ${(props) => (props.animating ? "hidden" : "visible")} !important;
  display: flex;
  column-gap: 20px;
  opacity: 0;
  transition: all 1s ease-in-out;
  transition-delay: 0s;
  pointer-events: none;
  div {
    flex: 1 0;
    opacity: ${(props) => (props.animating ? 0 : 1)};
    visibility: ${(props) =>
      props.animating ? "hidden" : "visible"} !important;
  }
`;

const Slide = styled.div`
  transition: all 1s ease-in-out !important;
  transition-delay: 0.5s !important;
  margin-right: 10px;
  margin-left: 10px;
  max-height: 70vh;
  pointer-events: auto;
  position: relative;
`;

const CarouselWide2 = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityCarousel {
        edges {
          node {
            slides {
              description
              product {
                slug {
                  current
                }
              }
              image {
                asset {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const [slidehovered, setSlideHovered] = useState(false);
  const [blurbtext, setBlurbText] = useState(false);
  const [quarter, setSixth] = useState(1);

  const [slideIndex, setSlideIndex] = useState(0);
  const [slide1, setSlide1] = useState(false);
  const [slide2, setSlide2] = useState(false);
  const [slide3, setSlide3] = useState(false);
  const [slide4, setSlide4] = useState(false);
  const [slide5, setSlide5] = useState(false);
  const [slide6, setSlide6] = useState(false);

  const [seen, setSeen] = useSessionStorage("products", false);

  useEffect(() => {
    if (!seen) {
      const id = setTimeout(() => {
        setSeen("true");
      }, 4000);
      return () => clearTimeout(id);
    }
  }, []);

  const [animating, setAnimating] = useState(false);

  const slides = [...data.allSanityCarousel.edges[0].node.slides];

  const nba = infiniteArray(slides);

  useEffect(() => {
    if (slide1 === false) {
      setSlide1(nba[0]);
    } else {
      setSlide1(nba[slideIndex]);
    }

    if (slide2 === false) {
      setSlide2(nba[1]);
    } else {
      setSlide2(nba[slideIndex + 1]);
    }

    if (slide3 === false) {
      setSlide3(nba[2]);
    } else {
      setSlide3(nba[slideIndex + 2]);
    }

    if (slide4 === false) {
      setSlide4(nba[3]);
    } else {
      setSlide4(nba[slideIndex + 3]);
    }

    if (slide5 === false) {
      setSlide5(nba[4]);
    } else {
      setSlide4(nba[slideIndex + 4]);
    }

    if (slide6 === false) {
      setSlide5(nba[5]);
    } else {
      setSlide4(nba[slideIndex + 5]);
    }

  }, [slideIndex]);

  const window_size = useWindowSize();

  const { width } = window_size;

  const handleMouseMove = (event) => {
    const sixths = event.view?.innerWidth ? event.view.innerWidth / 6 : 1;
    const x = parseInt(event.clientX - event.target.offsetLeft);
    if (x > 0 && x < sixths) {
      setSixth(1);
    } else if (x >= sixths && x < sixths * 2) {
      setSixth(2);
    } else if (x >= sixths * 2 && x < sixths * 3) {
      setSixth(3);
    } else if (x >= sixths * 3 && x < sixths * 4) {
      setSixth(4);
    } else if (x >= sixths * 4 && x < sixths * 5) {
      setSixth(5);
    } else if (x >= sixths * 5 && x < sixths * 6) {
      setSixth(6);
    } else {
      setSixth(0);
    }
  };

  const flickRef = useRef(null);

  const next_slide = (e) => {
    if (flickRef.current.animating === false) {
      flickRef.current.next();
      setSlideIndex(slideIndex + 1);
    }
  };

  const prev_slide = (e) => {
    if (flickRef.current.animating === false) {
      flickRef.current.prev();
      setSlideIndex(slideIndex - 1);
    }
  };

  const handleScroll = (e) => {
    if (e < 0) {
      prev_slide(e);
    } else if (e > 0) {
      next_slide(e);
    }
    handleAnimate();
  };

  const handleAnimate = () => {
    if (!animating) {
      setAnimating(true);
      const id = setTimeout(() => {
        setAnimating(false);
      }, 1500);
      return () => clearTimeout(id);
    }
  };

  return (
    <Outer onWheel={(e) => handleScroll(e.deltaY)}>
      <LeftRightContainer seen={seen}>
        <Left seen={seen} onClick={(e) => prev_slide(e)}></Left>
        <Right seen={seen} onClick={(e) => next_slide(e)}></Right>
      </LeftRightContainer>

      <Container onMouseMove={handleMouseMove}>
        {width && (
          <Flicking
            align="prev"
            ref={flickRef}
            inputType={["touch"]}
            panelsPerView={6}
            interruptable={false}
            preventClickOnDrag={true}
            duration={1500}
            easing={(x) => 1 - Math.pow(1 - x, 3)}
            circular={true}
          >
            {slides.map((s, index) => {
              return (
                <Slide
                  key={index + 1}
                  className={
                    slidehovered && slidehovered === index + 1
                      ? "container-hover"
                      : "container-not-hover"
                  }
                >
                  <CurvedImage
                    blurb={s.description}
                    link={`/${s.product.slug.current}`}
                    image={s.image.asset.url}
                    seen={seen}
                    setSlideHovered={setSlideHovered}
                    setBlurbText={setBlurbText}
                    slidehovered={slidehovered}
                    index={index}
                  />
                </Slide>
              );
            })}
          </Flicking>
        )}

        <Blurbs className="blurb-container" animating={animating}>
          <div>
            <Blurb className={quarter == 1 ? "blurb v" : "blurb h"}>
              {slide1.description}
            </Blurb>
          </div>
          <div>
            <Blurb className={quarter == 2 ? "blurb v" : "blurb h"}>
              {slide2.description}
            </Blurb>
          </div>
          <div>
            <Blurb className={quarter == 3 ? "blurb v" : "blurb h"}>
              {slide3.description}
            </Blurb>
          </div>
          <div>
            <Blurb className={quarter == 4 ? "blurb v" : "blurb h"}>
              {slide4.description}
            </Blurb>
          </div>
          <div>
            <Blurb className={quarter == 5 ? "blurb v" : "blurb h"}>
              {slide5.description}
            </Blurb>
          </div>
          <div>
            <Blurb className={quarter == 6 ? "blurb v" : "blurb h"}>
              {slide6.description}
            </Blurb>
          </div>
        </Blurbs>
      </Container>
    </Outer>
  );
};

export default CarouselWide2;
